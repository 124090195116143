
    let btnClosest = document.querySelector('.btnClosestExpert')
    let currentPage = document.querySelector('.page-template');
    let oms = null;

    if (typeof currentPage != "undefined" && currentPage != null  && currentPage.classList.contains('page-template-page-items')) {

        let fadeMe = document.querySelector('.fadeMe');
        fadeMe.style.display = 'none';
        const agencies = JSON.parse($('#agencies_json').html());

        const map = initMap(map)

        /**
         * Initialisation de la map
         *
         * @return void
         */
        function initMap(map) {
            let infoWindow = new google.maps.InfoWindow();

            // Create the google map
            map = new google.maps.Map(document.getElementById('mapexperts'), {
                zoom: 5,
                center: {lat: 47.17, lng: 2.4},
            });



            // Create oms Instance
            oms = new OverlappingMarkerSpiderfier(map, {
                markersWontMove: true,
                markersWontHide: true,
                basicFormatEvents: true
            });

            // Add markers to map
            let markers = []
            for (let agency of agencies) {
                let marker = new google.maps.Marker({
                    title: `${agency.title}`,
                    position: { lat: parseFloat(agency.latitude), lng: parseFloat(agency.longitude) },
                    permalink: agency.permalink,
                    responsable_agence: agency.responsable_agence,
                    email_agence: agency.email_agence,
                    adresse_agence: agency.adresse_agence,
                    telephone_agence: agency.telephone_agence,
                    fax_agence: agency.fax_agence,
                    horaires_agence: agency.horaires_agence,
                    numero_et_departement: agency.numero_et_departement,
                });
                markers.push(marker)
                oms.addMarker(marker);
            }

            // Add a marker clusterer to manage the markers.
            const markerCluster = new markerClusterer.MarkerClusterer({ map, markers,
                    maxZoom: 10,
                    imagePath: 'https://'+window.location.hostname+'/wp-content/themes/alliance/assets/images/map/m'
                }
            )

            // Search result
            oms.addListener('click', function(marker, event) {
                let content = getContentMarker(marker);
                infoWindow.setContent(content);
                infoWindow.open(map, marker);
            });

            // Useful ?
            // oms.addListener('spiderfy', function(markers) {
            //     infoWindow.close();
            // });

            btnClosest.addEventListener('click', (event) => {
                event.preventDefault()

                fadeMe.style.display = 'flex';

                setTimeout(() => {}, 1000);

                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position) => {
                        let currentLat = position.coords.latitude
                        let currentLong = position.coords.longitude
                        let closest = find_closest_marker( agencies, currentLat, currentLong )
                        let i = closest;
                        let index = oms.markers.findIndex( (v) => {
                            if (v.title == i.title) {
                                return true;
                            }
                        });

                        let content = getContentMarker(oms.markers[index]);

                        infoWindow.setContent(content);
                        infoWindow.open(map, oms.markers[index]);

                        map.setCenter({ lat: parseFloat(i.latitude), lng: parseFloat(i.longitude) })
                        map.setZoom(16)

                        fadeMe.style.display = 'none';
                    });
                } else {
                    alert("Géolocalisation non supportée par votre navigateur");
                }
            });

            autocomplete(document.querySelector("#search-agence"), agencies, map, infoWindow);

            return map;
        }

        function getContentMarker(marker) {
            return `<div class="mapPopin">
                    <h4>${marker.title}</h4>
                    <p>Horaires : ${(marker.horaires_agence) ? marker.horaires_agence : 'N.C'}</p>
                    <p>Adresse : ${(marker.adresse_agence) ? marker.adresse_agence : 'N.C'}</p>
                    <br>
                    <p style="text-align: center;"><a class="btn primary-background" href="${marker.permalink}"><span>Voir ${marker.title}</span></a></p>
                </div>
            `;
        }

        function autocomplete(inp, agencies, map, infoWindow) {
            var currentFocus;

            inp.addEventListener("input", function(e) {
                let autocompleteList;
                let list;
                let i;
                let val = this.value;
                let mainContentResult = document.querySelector('.main-content__result');
                closeAllLists();

                if (!val) { return false;}
                currentFocus = -1;

                // Create div
                autocompleteList = document.createElement("div");
                autocompleteList.setAttribute("id", this.id + "autocomplete-list");
                autocompleteList.setAttribute("class", "autocomplete-items");
                mainContentResult.appendChild(autocompleteList);

                let regQuery = new RegExp(val, 'gi')

                for (let agency of agencies) {
                    let currentAgency = agency.numero_et_departement +' / ' + agency.title;
                    if (currentAgency.match(regQuery)) {
                        mainContentResult.classList.remove('hidden');
                        mainContentResult.classList.add('shown');

                        list = document.createElement("div");
                        list.innerHTML = "<strong>" + agency.numero_et_departement + ' / ' + agency.title + "</strong>";
                        /*insert a input field that will hold the current array item's value:*/
                        list.innerHTML += "<input type='hidden' value='" + agency.numero_et_departement + ' / ' + agency.title + "'>";

                        // Execute a function when someone clicks on the item value (DIV element)
                        list.addEventListener("click", function(e) {
                            mainContentResult.classList.remove('shown');
                            mainContentResult.classList.add('hidden');

                            inp.value = this.getElementsByTagName("input")[0].value;

                            if (agency.latitude !== '' && agency.longitude !== '') {
                                let index = oms.markers.findIndex( v => v.title === agency.title);
                                let content = getContentMarker(agency);

                                infoWindow.setContent(content);
                                infoWindow.open(map, oms.markers[index]);

                                map.setZoom(16);
                                map.setCenter({ lat: parseFloat(agency.latitude), lng: parseFloat(agency.longitude) });
                            }

                            closeAllLists();
                        });

                        autocompleteList.appendChild(list);
                    }
                }
            });

            inp.addEventListener("keydown", function(e) {
                var x = document.getElementById(this.id + "autocomplete-list");
                if (x) x = x.getElementsByTagName("div");
                if (e.keyCode == 40) {
                    currentFocus++;
                    addActive(x);
                } else if (e.keyCode == 38) { //up
                    currentFocus--;
                    addActive(x);
                } else if (e.keyCode == 13) {
                    e.preventDefault();
                    if (currentFocus > -1) {
                        if (x) x[currentFocus].click();
                    }
                }
            });

            function addActive(x) {
                if (!x) return false;
                removeActive(x);
                if (currentFocus >= x.length) currentFocus = 0;
                if (currentFocus < 0) currentFocus = (x.length - 1);
                x[currentFocus].classList.add("autocomplete-active");
            }

            function removeActive(x) {
                for (var i = 0; i < x.length; i++) {
                    x[i].classList.remove("autocomplete-active");
                }
            }

            function closeAllLists(elmnt) {
                var x = document.getElementsByClassName("autocomplete-items");
                for (var i = 0; i < x.length; i++) {
                    if (elmnt != x[i] && elmnt != inp) {
                        x[i].parentNode.removeChild(x[i]);
                    }
                }
            }
        }

        function find_closest_marker( agencies, lat1, lon1 ) {
            let gmarkers = [];
            let pi = Math.PI;
            let R = 6371; //equatorial radius
            let distances = [];
            let closest = -1;

            for (let agency in agencies) {
                let lat2 = agencies[agency].latitude;
                let lon2 = agencies[agency].longitude;

                let chLat = lat2-lat1;
                let chLon = lon2-lon1;

                let dLat = chLat*(pi/180);
                let dLon = chLon*(pi/180);

                let rLat1 = lat1*(pi/180);
                let rLat2 = lat2*(pi/180);

                let a = Math.sin(dLat/2) * Math.sin(dLat/2) +
                    Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(rLat1) * Math.cos(rLat2);
                let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
                let d = R * c;

                distances[agency] = d;

                if ( closest == -1 || d < distances[closest] ) {
                    closest = agency;
                }
            }

            return agencies[closest];
        }

    }
