$(function() {
    if ($('#agences_json').html()) {
        const agences_json = $('#agences_json').html();
        const agences = JSON.parse(agences_json);

        const regionsToColorized = [
            'FR-83',
            'FR-13',
            'FR-04',
            'FR-06',
        ];

        // récupération des agences
        const markers = agences.map( agence => {
            return {
                latLng: [agence.latitude, agence.longitude],
                name: agence.title,
                permalink: agence.permalink
            }
        });

        let map = new jvm.Map({
            container: $('#french_map'),
            map: 'fr_merc',
            backgroundColor: 'white',
            enableZoom: false,
            zoomOnScroll: false,
            hoverColor: true,
            regionStyle: {
                initial: {
                    fill: '#3498db'
                },
                hover: {
                    "fill-opacity": 1,
                    cursor: 'arrow',
                },
                selected: {
                    fill: '#255379'
                }
            },
            markerStyle: {
                initial: {
                    fill: 'white', // POI BACKGROUND
                    stroke: '#f19300', // POI BORDER
                    "fill-opacity": 1,
                    "stroke-width": 6,
                    "stroke-opacity": 1,
                    r: 8
                },
                hover: {
                    stroke: 'black',
                    "stroke-width": 10,
                    cursor: 'pointer',
                    r: 12
                },
                selected: {
                    fill: 'blue'
                },
                selectedHover: {
                }
            },
            markers: markers,
            // prevent tooltip display on region hover
            onRegionTipShow: function (e, tip, code) {
                e.preventDefault();
            },
            // Add link on markers
            onMarkerClick: function (e, code) {
                console.log(markers[code].permalink);
                window.location.href = markers[code].permalink;
            }
        });

        map.setSelectedRegions(regionsToColorized);
    }
});
